import { deserializeStorage, serializeStorage } from "./App";
import { copyImportCode } from "./Game";
import { gameName, maxGuesses } from "./util";
import React, { useRef, useEffect } from 'react';
import { Row, RowState } from "./Row";
import { Clue } from "./clue";

export async function pasteImportCode() {
  const enteredCode = prompt('Please paste your import code');
  if ( enteredCode !== null ) {
    deserializeStorage(enteredCode);
  }
}

export function About() {

  const isHttp = window.location.protocol === "http:";
  const importCodeRef = useRef<HTMLTextAreaElement>(null);

  const handleTextareaClick = () => {
    if (importCodeRef.current) {
      importCodeRef.current.select();
    }
  };

  useEffect(() => {
    if (importCodeRef.current) {
      importCodeRef.current.focus();
      importCodeRef.current.select();
    }
  }, []);

  let importLink = (
  <div>
  <hr/>
  <p><h2>Transferring game history to a new device or browser</h2></p>
  <p>
    <p>Click <button onClick={() =>{copyImportCode();}}>here</button> to copy an import code to your clipboard which you can use to transfer your stats to another device.</p>
    
    {isHttp && 
    <p>Note: You are on the non-secure site (http rather than https.) Non-secure sites can't access the clipboard. You have to manually copy it from the text area below with Ctrl+C or Cmd+C. It is recommended you do so and switch to the secure site <a href="https://letterbound.com">https://letterbound.com</a>
      <textarea id="import-code" className="import-code" readOnly rows={10} ref={importCodeRef} onClick={handleTextareaClick}>{serializeStorage()}</textarea>
    </p>
    }

    <p>Click <button onClick={() =>{pasteImportCode();}}>here</button> to paste an import code from your clipboard.</p>
  </p>
  </div>
  );

  return (
    <div className="App-about">
        <p>
          {gameName} is a variant of Wordle by Josh Wardle and is one of four sibling sites.{" "}
          <br /><br /> <a href="https://xordle.org">Xordle</a> by <a href="https://twitter.com/kellydornhaus">keldor</a><br/>Two secret words, one board, no overlap between the words. 
          <br /><br /> <a href="https://double-dabble.com">Double-Dabble</a> by K &amp; R Garfield, coded by keldor <br/>Each letter is bound to another letter.
          <br /><br /> <a href="https://letterbound.com">LetterBound</a> by keldor <br/>Like Wordle.
          <br /><br /> <a href="https://fibble.xyz">Fibble</a> by K &amp; R Garfield, coded by keldor <br/>Lies to you once per row.
          <br /><br /> <a href="https://warmle.org">Warmle</a> by Mike Elliott, coded by keldor <br/>Yellows tell you if you've gotten close in that position.
          <br /><br />Code based on a fork of <a href="https://github.com/lynn/hello-wordl">hello wordl</a>.
        </p>
      <hr />        
      <p className="App-instructions">
        <h1>LetterBound Rules!</h1>
        It certainly does.
        <br />You get {maxGuesses} tries to guess the secret word.
        <br />
        <br />Letters in your guess are:
        <br />🟩 Green if you guessed a letter in the right place.
        <br />🟨 Yellow if the letter is somewhere else in the word.
        <br />⬛ Grey if the letter is not in the word.
        <br />
        <br />For example, you might guess the pairs BLAST. If the secret word was CLOUT, you'll receive:
        <br />   
            <Row
              onClick={() => {}}
              currentGuess={["", -1]}
              rowState={RowState.LockedIn}
              rowNumber={0}
              cluedRows={[]}
              cluedLetters={[
                { clue: Clue.Absent, letter: "B" },
                { clue: Clue.Correct, letter: "L" },
                { clue: Clue.Absent, letter: "A" },
                { clue: Clue.Absent, letter: "S" },
                { clue: Clue.Correct, letter: "T" },
              ]}
              letterInfo={new Map()}
              rowWidth={5}
            />
        <br />However if the secret word was LABEL then you would receive:
        <Row
              currentGuess={["", -1]}
              onClick={() => {}}
              rowState={RowState.LockedIn}
              rowNumber={0}
              cluedRows={[]}
              cluedLetters={[
                { clue: Clue.Elsewhere, letter: "B" },
                { clue: Clue.Elsewhere, letter: "L" },
                { clue: Clue.Elsewhere, letter: "A" },
                { clue: Clue.Absent, letter: "S" },
                { clue: Clue.Absent, letter: "T" },
              ]}
              letterInfo={new Map()}
              rowWidth={5}
          />
          <br />
      </p>
      <hr />      
      <p>
        <h1>Contact info</h1>
        You can send me an <a href="mailto:kellydornhaus@gmail.com">email</a> about whatever is on your mind.<br/>
      </p>
      {importLink}
      <p>
      </p>
    </div>
  );
}
